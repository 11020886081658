<template>
  <div>
    <div class="mb-3 pb-5">
      <div class="font-weight-bold mb-3 bg-secondary">Dados do cliente:</div>
      <div class="line-height-md">
        <b-row>
          <b-col class="font-weight-bold">Nome:</b-col>
          <b-col>{{ wizard.cliente.nome }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Franquia mínima:</b-col>
          <b-col>{{ wizard.cliente.franquiaMinima }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">CEP:</b-col>
          <b-col>{{ wizard.cliente.cep }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Tipo de negócio:</b-col>
          <b-col>{{ tipoNegocio }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5">
      <div class="font-weight-bold mb-3 bg-secondary">Dados da conta:</div>
      <div class="line-height-md">
        <b-row>
          <b-col class="font-weight-bold">Sigla:</b-col>
          <b-col>{{ wizard.conta.sigla }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Login:</b-col>
          <b-col>{{ wizard.conta.login }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Senha:</b-col>
          <b-col>{{ wizard.conta.senha }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5">
      <div class="font-weight-bold mb-3 bg-secondary">Dados do plano:</div>
      <div class="line-height-md">
        <b-row>
          <b-col class="font-weight-bold">Tipo de plano:</b-col>
          <b-col>{{ tipoPlano }}</b-col>
        </b-row>

        <template v-if="wizard.plano.tipoPlano == 'POS'">
          <b-row>
            <b-col class="font-weight-bold">Limite de crédito:</b-col>
            <b-col>{{ wizard.plano.limiteCredito }}</b-col>
          </b-row>
        </template>

        <b-row>
          <b-col class="font-weight-bold">Mensagens longas: </b-col>
          <b-col>{{
            wizard.plano.permitirMensagensLongas ? "Sim" : "Não"
          }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5">
      <div class="font-weight-bold mb-3 bg-secondary">Dados do usuário:</div>
      <div class="line-height-md">
        <b-row>
          <b-col class="font-weight-bold">Nome:</b-col>
          <b-col>{{ wizard.usuario.nome }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Email:</b-col>
          <b-col>{{ wizard.usuario.email }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Celular:</b-col>
          <b-col>{{ wizard.usuario.celular }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Senha:</b-col>
          <b-col>{{ wizard.usuario.senha }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5">
      <div class="font-weight-bold mb-3 bg-secondary">Módulos ativos:</div>
      <div class="line-height-md">
        <b-row>
          <b-col class="font-weight-bold">Módulo Atende: </b-col>
          <b-col>{{ moduloAtendeAtivo ? "Sim" : "Não" }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Módulo Agende: </b-col>
          <b-col>{{ moduloAgendeAtivo ? "Sim" : "Não" }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5" v-if="moduloAtendeAtivo">
      <div class="font-weight-bold mb-3 bg-secondary">
        Configurações do Atende:
      </div>
      <div class="line-height-md">
        <b-row class="mb-2">
          <b-col class="font-weight-bold">Dados da conta:</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Nome:</b-col>
          <b-col>{{
            wizard.modulos.atende.configuracoes.contaLiveChat.conta.nome
          }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold"
            >Qtd. máx. de caixas de entrada:</b-col
          >
          <b-col>{{
            wizard.modulos.atende.configuracoes.contaLiveChat.conta
              .qtdMaxCaixaEntrada
          }}</b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Qtd. máx. de usuários:</b-col>
          <b-col>{{
            wizard.modulos.atende.configuracoes.contaLiveChat.conta
              .qtdMaxUsuarios
          }}</b-col>
        </b-row>
        <b-row>
          <b-col class="font-weight-bold"
            >Criar caixa de entrada padrão:
          </b-col>
          <b-col>{{ wizard.modulos.atende.criarCaixa ? "Sim" : "Não" }}</b-col>
        </b-row>
        <b-row class="mb-2 mt-3">
          <b-col class="font-weight-bold"
            >Dados do usuário administrador:</b-col
          >
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Nome: </b-col>
          <b-col
            >{{
              wizard.modulos.atende.configuracoes.contaLiveChat.usuario.nome
            }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Email:</b-col>
          <b-col
            >{{
              wizard.modulos.atende.configuracoes.contaLiveChat.usuario.email
            }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="font-weight-bold">Senha:</b-col>
          <b-col>{{
            wizard.modulos.atende.configuracoes.contaLiveChat.usuario.senha
          }}</b-col>
        </b-row>
      </div>
    </div>

    <div class="mb-3 pb-5" v-if="moduloAgendeAtivo">
      <div class="font-weight-bold mb-3 bg-secondary">
        Configurações do Agende:
      </div>
      <div class="line-height-md">
        <!--TODO: Implementar as configurações do AGENDE-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wizard: Object,
    moduloAtendeAtivo: Boolean,
    moduloAgendeAtivo: Boolean,
    tiposNegocio: {
      type: Array,
      default: () => []
    },
    tiposPlano: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    tipoNegocio() {
      const tipoNegocio = this.tiposNegocio.find(
        (tipo) => tipo.value == this.wizard.cliente.tipoNegocio
      );

      return tipoNegocio.text == "Selecione"
        ? "Não informado"
        : tipoNegocio.text;
    },

    tipoPlano() {
      const tipoPlano = this.tiposPlano.find(
        (tipo) => tipo.value == this.wizard.plano.tipoPlano
      );

      return tipoPlano.text;
    }
  }
};
</script>
