<template>
  <b-form ref="frmModuloAtende">
    <h6>DADOS DA CONTA</h6>
    <div class="separator separator-solid mb-3"></div>

    <b-form-group>
      <label>Nome <span class="text-danger">*</span>:</label>
      <b-form-input
        name="contaNome"
        v-model="wizard.modulos.atende.configuracoes.contaLiveChat.conta.nome"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group>
          <label
            >Qtd. Máx. Caixa de Entrada
            <span class="text-danger">*</span>:</label
          >
          <b-form-input
            name="contaQtdMaxCaixaEntrada"
            v-model="
              wizard.modulos.atende.configuracoes.contaLiveChat.conta
                .qtdMaxCaixaEntrada
            "
            data-inputmask="'alias': 'integer'"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>Qtd. Máx. Usuários <span class="text-danger">*</span>:</label>
          <b-form-input
            name="contaQtdMaxUsuarios"
            v-model="
              wizard.modulos.atende.configuracoes.contaLiveChat.conta
                .qtdMaxUsuarios
            "
            data-inputmask="'alias': 'integer'"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex">
      <p>Criar caixa de entrada padrão <span class="text-danger">*</span>:</p>
      <InputSwitch
        v-model="wizard.modulos.atende.criarCaixa"
        style="margin-left: 10px"
      ></InputSwitch>
    </div>

    <h6>DADOS DO USUÁRIO ADMINISTRADOR</h6>
    <div class="separator separator-solid mb-3"></div>

    <b-form-group>
      <label>Nome <span class="text-danger">*</span>:</label>
      <b-form-input
        name="usuarioNome"
        v-model="wizard.modulos.atende.configuracoes.contaLiveChat.usuario.nome"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <label>Email <span class="text-danger">*</span>:</label>
      <b-form-input
        name="usuarioEmail"
        v-model="
          wizard.modulos.atende.configuracoes.contaLiveChat.usuario.email
        "
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group>
          <label>Senha <span class="text-danger">*</span>:</label>
          <b-form-input
            v-model="
              wizard.modulos.atende.configuracoes.contaLiveChat.usuario.senha
            "
            name="usuarioSenha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
          <span class="form-text text-muted"
            >A senha deve conter letras maiúsculas, minúsculas, número e um
            caractere especial.</span
          >
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>Confirmar Senha <span class="text-danger">*</span>:</label>
          <b-form-input
            v-model="
              wizard.modulos.atende.configuracoes.contaLiveChat.usuario
                .confirmarSenha
            "
            name="usuarioConfirmarSenha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <gerador-senha
          @usar-senha="usarSenha"
          :comprimento="10"
          :simbolos="true"
        ></gerador-senha>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import InputSwitch from "primevue/inputswitch";

import Inputmask from "inputmask";

export default {
  props: {
    wizard: Object
  },
  components: {
    InputSwitch
  },

  mounted() {
    const frmModuloAtende = this.$refs["frmModuloAtende"];

    Inputmask().mask(frmModuloAtende.querySelectorAll("input"));

    this.validator = formValidation(frmModuloAtende, {
      fields: {
        contaNome: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        contaQtdMaxCaixaEntrada: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        contaQtdMaxUsuarios: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        usuarioNome: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        usuarioEmail: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            emailAddress: {
              message: "O email informado é inválido."
            },
            blank: {}
          }
        },
        usuarioSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            stringLength: {
              min: 8,
              message:
                "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
            },
            regexp: {
              regexp:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message:
                "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
            },
            blank: {}
          }
        },
        usuarioConfirmarSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            identical: {
              message: 'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
              compare: function () {
                return this.frmCriarConta.senha;
              }
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });
  },

  methods: {
    usarSenha(senha) {
      this.wizard.modulos.atende.configuracoes.contaLiveChat.usuario.senha =
        senha;
      this.wizard.modulos.atende.configuracoes.contaLiveChat.usuario.confirmarSenha =
        senha;
    }
  }
};
</script>
